// @see https://stackoverflow.com/a/27232658
function webpSupport() {
  var elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  } else {
    return false;
 }
}

if(!webpSupport()) {
  console.log('webp support unavailable fallback');
  var elements = document.querySelectorAll('[data-webp-fallback]');
  for (let i = 0, n = elements.length; i < n; i++) {
    elements[i].setAttribute('src', elements[i].dataset.webpFallback);
  };
}
