var TapTapGo = function (selector) {
  var selector, lastElement;

  var processInteraction = function (event) {
    if (getParents(event.target).includes(lastElement) !== true) {
      event.preventDefault();
    }

    lastElement = event.target.parentNode;
  };

  // https://stackoverflow.com/a/12981248
  var getParents = function (element) {
    var parents = [];
    var p = element.parentNode;

    while (p !== document) {
      var o = p;
      parents.push(o);
      p = o.parentNode;
    }

    parents.push(document);
    return parents;
  };


  var bindEvents = function () {
    var elements = document.querySelectorAll(selector);
    for (let i = 0, n = elements.length; i < n; i++) {
      elements[i].addEventListener('click', processInteraction);
    }
  };

  this.init = function () { bindEvents(); }();
};

if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
  new TapTapGo('.primary-header-navigation-list__item--dropdown')
}
