let youTubeEmbedinitializer;
if (typeof Turbolinks == 'undefined') {
  youTubeEmbedinitializer = 'DOMContentLoaded'
} else {
  youTubeEmbedinitializer = 'turbolinks:load';
}

const YouTubeLazyLoad = (function () {
  var self = {};
  var embedId;

  var modalActiveClass = 'youtube-embed-modal-active';
  var modalClassName = 'youtube-embed-modal';

  var source = function () {
    return 'https://img.youtube.com/vi/' + embedId + '/sddefault.jpg';
  }

  var highRes = function () {
    return 'https://img.youtube.com/vi/' + embedId + '/maxresdefault.jpg';
  }

  var youtubeSrc = function () {
    return 'https://www.youtube-nocookie.com/embed/' + embedId + '?rel=0&showinfo=0&autoplay=1'
  }

  var youtubeEmbeds = function () {
    return document.querySelectorAll('.youtube');
  }

  var assignEvent = function (selector, eventFunction) {
    let elements = document.querySelectorAll(selector);
    for (let i = 0, n = elements.length; i < n; i++) {
      eventFunction(elements[i]);
    }
  }

  var createThumbnail = function(embed) {
    embedId = embed.dataset.embed;

    if (embed.dataset.background === undefined) {
      createThumbnailImage(embed);
    } else {
      createBackgroundImage(embed);
    }
  }

  var createThumbnailImage = function (embed) {
    let image = new Image();

    if(embed.dataset.thumbnail === undefined){
      image.src = source();
      image.srcset = highRes() + ' 2x';
    } else {
      image.src = embed.dataset.thumbnail;
    }

    image.addEventListener('load', function () {
      embed.appendChild(image);
    }(embed));
  }

  var createBackgroundImage = function (embed) {
    let src;

    if(embed.dataset.thumbnail === undefined){
      src = highRes();
    } else {
      src = embed.dataset.thumbnail;
    }

    embed.style.backgroundImage = "url('" + src + "')";
    embed.style.backgroundPosition = 'center';
    embed.style.backgroundSize = 'cover';
  }

  var createiFrame = function (embed) {
    embedId = embed.dataset.embed;

    let iframe = document.createElement('iframe');

    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('allow', 'autoplay; encrypted-media');
    iframe.setAttribute('src', youtubeSrc());

    if (embed.dataset.modal === undefined) {
      embed.innerHTML = '';
      embed.appendChild(iframe);
      embed.classList.add('youtube--inline');
    } else {
      createModal(iframe)
    }
  }

  var createModal = function (iframe) {
    let elem = document.createElement('div');
    elem.className = modalClassName;

    let elemInner = document.createElement('div');
    elemInner.className = 'youtube-embed-modal__inner';
    elemInner.appendChild(createModalCloseButton());
    elemInner.appendChild(createModaliFrameWrap(iframe));

    elem.appendChild(elemInner);
    elem.onclick = function (elem) {
      document.querySelector('.' + modalClassName).remove();
      document.querySelector('body').classList.remove(modalActiveClass);
    }

    let body = document.querySelector('body');
    body.appendChild(elem);
    body.classList.add(modalActiveClass);
  }

  var createModalCloseButton = function () {
    let elem = document.createElement('div');
    elem.className = 'youtube-embed-modal__close';
    elem.innerHTML = '✖';

    return elem;
  }

  var createModaliFrameWrap = function (iframe) {
    let elem = document.createElement('div');
    elem.className = 'youtube-embed-modal__video';
    elem.appendChild(iframe);
    return elem;
  }

  self.init = function () {
    assignEvent('.youtube', function (element) {
      element.addEventListener('click', function() {
        createiFrame(this);
      }, false);

      createThumbnail(element);
    });
  }

  return self;
}());

window.YouTubeLazyLoad = YouTubeLazyLoad;


document.addEventListener('DOMContentLoaded', YouTubeLazyLoad.init);
