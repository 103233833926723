// import $ from 'jquery';
import 'slick-carousel/slick/slick.js';

// @see https://codepen.io/mafudelaptu/embed/PoGrgQP?default-tab=js%2Cresult&theme-id=1

let slickSliders = document.querySelectorAll('.js--slick');

if ('IntersectionObserver' in window) {
  let config = { root: null, rootMargin: '0px', threshold: 0.0 };
  let observer = new IntersectionObserver(onChange, config);
  slickSliders.forEach(slider => observer.observe(slider));

  function onChange(elements, observer) {
    elements.forEach(element => {
      if (element.isIntersecting) {
        // console.log("element intersecting", element.target);
        loadSlick(element.target);
        observer.unobserve(element.target);
      }
    });
  }
} else {
   for (let i = 0, n = slickSliders.length; i < n; i++) {
    loadSlick(slickSliders[i]);
  }
}

function loadSlick(slickSlider) {
  var options = {
    'js--home-resources': {
      dots: true,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    },
    'js--service-resources': {
      dots: true,
      arrows: true,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    },
    'js--footer-awards': {
      dots: true,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 9,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            arrows: false
          }
        }
      ]
    },
    'js--home-testimonials': {
      dots: false,
      arrows: false,
      autoplay: true,
      fade: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1
    },
    'js--page-section-accreditations': {
      dots: true,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]
    },
    'js--page-section-updates': {
      dots: true,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]
    },
    'js--service-team-members--large': {
      dots: false,
      arrows: true,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            arrows: true
          }
        }
      ]
    },
    'js--service-team-members--small': {
      dots: false,
      arrows: true,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            arrows: true
          }
        }
      ]
    },
    'js--service-testimonials': {
      dots: false,
      arrows: false,
      autoplay: true,
      fade: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  };

  var $slickSlider = $(slickSlider);
  $slickSlider.slick(options[$slickSlider.data('slick')]);
}

// $(document).on('turbolinks:load', function () {

$('.js--banners-slick').slick({
  dots: true,
  arrows: false,
  autoplay: true,
  fade: true,
  autoplaySpeed: 4000
});

/*
  // done
  $('.js--home-resources').slick({
    dots: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  });

  // done
  $('.js--service-resources').slick({
    dots: true,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  });

  // done
  $('.js--footer-awards').slick({
    dots: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          arrows: false
        }
      }
    ]
  });

  // done
  $('.js--page-section-accreditations').slick({
    dots: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  });

  // done
  $('.js--page-section-updates').slick({
    dots: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  });

  // done
  $('.js--service-team-members').slick({
    dots: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true
        }
      }
    ]
  });

  // done
  $('.js--home-testimonials').slick({
    dots: false,
    arrows: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1
  });

  // done
  $('.js--service-testimonials').slick({
    dots: false,
    arrows: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1
  });
});
*/
