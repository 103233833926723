/*
var $html;

$(document).on('ready turbolinks:load', function () {
  $html = $('.bot-loading');
});

$(document).on('click', '.bot-message__button', function () {
  $($html).insertAfter('.bot-initial-message:last');
  $('.bot-loading--visible, .bot-loading--visible .bot-team-member').css('opacity', 1);
  $('.bot-loading:last').addClass('bot-loading--visible');
});
*/

$(document).on('click', '.js--bot-message-button', function (e) {
  var messagePath = $(this).data('path');
  $.get(messagePath);
  console.log(messagePath);
  e.preventDefault();
});
