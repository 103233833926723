// import $ from 'jquery';

$(document).on('click', '.remote-form-modal-navigation__link:not(.remote-form-modal-navigation__link--phone)', function (e) {
  var container = $(this).data('container');
  $('.remote-form-modal__form').hide();
  $(container).show();
  $('.remote-form-modal-navigation__link').removeClass('remote-form-modal-navigation__link--active');
  $(this).addClass('remote-form-modal-navigation__link--active');

  e.preventDefault();
});

$(document).on('click', '.js--toggle-remote-form-modal', function(e) {
  $('body').addClass('overflow-hidden');
});

$(document).on('click', '.remote-form-modal, .remote-form-modal__close', function(e) {
  if (e.target !== this)
    return;

  $('.remote-form-modal').removeClass('remote-form-modal--callback-request remote-form-modal--contact');
  $('body').removeClass('overflow-hidden');
});
