const digitRegex = /^-?(\d+\.?\d*)$|(\d*\.?\d+)$/

$(document).on('focus, focusin, click', '.conveyancing-quotes-form__input', function () {

  if($('.js--banners-slick')) {
    $('.js--banners-slick').slick('slickPause');
  }

  $(this)
    .parent()
    .find('.conveyancing-quotes-form__options')
    .css('opacity', 1);
});

function validateForm() {
  var validateForm = $('.js--conveyancing-quotes-form-input--valid').length;
  if (validateForm) {
    $('.js--conveyancing-quotes-form-submit').removeClass('js--conveyancing-quotes-form-submit--disabled');
  } else {
    $('.js--conveyancing-quotes-form-submit').addClass('js--conveyancing-quotes-form-submit--disabled');
  }
}

$(document).on('keyup', '.js--conveyancing-quotes-form-input', function () {
  var input = $(this).val();


  if (digitRegex.test(input) && parseFloat(input) > 0) {
    $(this)
      .parent()
      .addClass('js--conveyancing-quotes-form-input--valid');

    $('.js--conveyancing-quotes-form-errors').slideUp(250);
  } else {
    $(this)
      .parent()
      .removeClass('js--conveyancing-quotes-form-input--valid');
  }

  validateForm();
});

function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

$(document).on('keyup', '.js--conveyancing-quotes-form-input', function (e) {
  if (e.which >= 37 && e.which <= 40) {
    e.preventDefault();
  }

  $(this).val(function (index, value) {
    value = value.replace(/,/g, '');
    $(this).data('original-value', value)
    return numberWithCommas(value);
  });

  // NOTE: Maximum fee scale is 2.5mil
  if(parseInt($(this).data('original-value')) > 2500000) {
    $('.js--conveyancing-quotes-form-submit').val('Please contact us for quotes over £2,500,000');
    $('.js--conveyancing-quotes-form-submit').attr('disabled', true);
  } else {
    $('.js--conveyancing-quotes-form-submit').val('Next');
    $('.js--conveyancing-quotes-form-submit').removeAttr('disabled');
  }
});

$(document).on('click', '.js--conveyancing-quotes-form-submit', function () {
  let filled = false;

  if ($('.js--conveyancing-quotes-form-input--valid').length) {
    filled = true;

    $(this).closest('.js--remote-form--conveyancing-quotes-sale-and-purchase').addClass('js--remote-form--conveyancing-quotes-sale-and-purchase--submitted');
    $(this).closest('.js--remote-form--conveyancing-quotes-sale-and-purchase').parent().addClass('js--remote-form--conveyancing-quotes-wrap--submitted');
  }

  if (!filled) {
    $(this).addClass('js--conveyancing-quotes-form-submit--shake');
    $('.js--conveyancing-quotes-form-errors').slideDown(250);

    setTimeout(function () {
      $('.js--conveyancing-quotes-form-submit')
        .removeClass('js--conveyancing-quotes-form-submit--shake');
    }, 500);

    return false;
  }
});

$(document).on('click', '.conveyancing-quotes-form__options-label', function () {
  console.log($(this).parent().parent().parent().find('input[type="checkbox"]'));
  $(this).parent().parent().parent().find('input[type="checkbox"]').prop('checked', false);
  $(this).closest('input[type="checkbox"]').prop('checked', true);
});

$(document).on('click', '.conveyancing-cost-list__toggle', function () {
  let $formId = $('#' + $(this).data('id'));

  $formId.trigger('click');

  $(this).addClass('conveyancing-cost-list__toggle--hidden');
  $(this).siblings().removeClass('conveyancing-cost-list__toggle--hidden');

  let $closestForm = $(this).closest('form');
  // $(this).closest('.conveyancing-cost-list__item').toggleClass('conveyancing-cost-list__item--active');

  $closestForm.find('input[type="submit"]').trigger('click');

  $closestForm.parent()
    .find('.conveyancing-costs-sub-total__emphasis')
    .html('<div class="loader" />');

  return false;
});


$(document).on("click", ".js--toggle-class-closest", function (e) {
  var toggleContainer = $(this).data('container');
  var dataReturn = $(this).data('return');

  $(this).parent().parent().find(toggleContainer).toggleClass('hide');

  if (dataReturn === false) {
    e.preventDefault();
  }
});

$(document).on("click", ".js--toggle-class-parent", function (e) {
  var dataReturn = $(this).data('return');

  $(this).parent().toggleClass('hide');

  if (dataReturn === false) {
    e.preventDefault();
  }
});

function conveyancingOwnershipOptionToggle(elem) {
  var $elem = elem;
  var val = $elem.find('option:selected').val();
  $elem.parent().parent().find('.js--conveyancing-quotes-form-ownership-options input[type=checkbox]').attr('checked', false);
  $elem.parent().parent().find('.js--conveyancing-quotes-form-ownership-options__' + val + ' input[type=checkbox]').attr('checked', true);
}

function conveyancingOwnershipOptions() {
  $('.js--conveyancing-quotes-form-ownership-option').each(function() {
    conveyancingOwnershipOptionToggle($(this));
  });
}

$(document).on('change', '.js--conveyancing-quotes-form-ownership-option', function(){
  conveyancingOwnershipOptionToggle($(this));
});

$(function() {
  var index = 0;

  $('.js--remote-form--conveyancing-quotes-sale-and-purchase').each(function() {
    var $form = $(this);
    index += 1;

    $form.find('.conveyancing-quotes-form__field [id!=""]').each(function () {
      var idField = $(this).attr('id');

      if (idField !== undefined) {
        $(this).attr('id', idField + '_' + index);
      }
    });

    $form.find('.conveyancing-quotes-form__field [for!=""]').each(function () {
      var idField = $(this).attr('for');

      if (idField !== undefined) {
        $(this).attr('for', idField + '_' + index);
      }
    });
  });

  conveyancingOwnershipOptions();
});
